import { Action } from '@manigo/manigo-commons';
import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { addLoadingState } from 'utils/redux-tools';

import {
    CLEAR_CURRENT_USER,
    EXTEND_TOKEN_VALIDITY, EXTEND_TOKEN_VALIDITY_FAILURE,
    EXTEND_TOKEN_VALIDITY_SUCCESS,
} from 'store/current-user/actions.types';
import { CLEAR_ALL_PASSCODE_DIGITS } from 'store/otp/actions.types';

import {
    ACTIVATE_USER, ACTIVATE_USER_FAILURE, ACTIVATE_USER_SUCCESS, CLEAR_BLOCK_SEND_CONFIRMATION_CODE,
    CLEAR_LOGIN_STATE, CLEAR_REQUEST_PASSWORD_RESET_STATE,
    CONFIRM_LOGIN,
    CONFIRM_LOGIN_FAILURE,
    CONFIRM_LOGIN_SUCCESS,
    GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS,
    INITIALISE_LOGIN,
    INITIALISE_LOGIN_FAILURE,
    INITIALISE_LOGIN_SUCCESS,
    REFRESH_TOKEN, REFRESH_TOKEN_FAILURE, REFRESH_TOKEN_SUCCESS,
    REQUEST_PASSWORD_RESET,
    REQUEST_PASSWORD_RESET_FAILURE,
    REQUEST_PASSWORD_RESET_SUCCESS, RESEND_CONFIRMATION_CODE_COUNTDOWN_TIME, SEND_CONFIRMATION_CODE, SEND_CONFIRMATION_CODE_FAILURE, SEND_CONFIRMATION_CODE_SUCCESS,
    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_FAILURE,
    SET_NEW_PASSWORD_SUCCESS,
    SIGN_UP,
    SIGN_UP_FAILURE,
    SIGN_UP_SUCCESS,
} from './actions.types';
import handleAuthFailure from './reducer.helpers';
import { AuthorisationStateType } from './reducer.types';


export const authorisationReducerName = 'authorisation';

const initialState: AuthorisationStateType = {
    isLoadingActivateUser: false,
    isLoadingLoginForm: false,
    isLoadingLoginConfirmationForm: false,
    isLoadingForgetPassword: false,
    isLoadingSetNewPassword: false,
    isLoadingSignUpForm: false,
    isLoadingSendConfirmationCode: false,
    isRefreshingSession: false,

    loginRequestId: undefined,
    loginToken: undefined,
    submittedUserEmail: undefined,
    passwordResetLinkSent: undefined,
    loginFormErrorMessage: undefined,
    loginConfirmFormError: undefined,
    signUpFormErrorMessage: undefined,

    requestPasswordResetErrorMessage: undefined,

    sendConfirmationCodeBlockUntil: undefined,
    loginFormRememberedUserEmail: undefined,
    loginBlockUntil: undefined,
    loginPermanentBlock: false,
};

const createAuthorisationReducerBuilderCases = (builder: ActionReducerMapBuilder<AuthorisationStateType>) => {
    builder
        .addCase(INITIALISE_LOGIN, (state, action: Action) => {
            state.isLoadingLoginForm = true;
            state.loginFormErrorMessage = undefined;
            state.submittedUserEmail = action.payload.email;
        })
        .addCase(INITIALISE_LOGIN_SUCCESS, (state, action: Action) => {
            state.isLoadingLoginForm = false;
            state.loginRequestId = action.payload.id;
            state.loginToken = action.payload.token;
        })
        .addCase(INITIALISE_LOGIN_FAILURE, (state, action: Action) => {
            const errorMessage = action.payload.error.message;

            state.isLoadingLoginForm = false;
            state.loginFormErrorMessage = errorMessage;
            state.submittedUserEmail = action.payload.email;

            handleAuthFailure(state, errorMessage);
        })
        .addCase(CLEAR_LOGIN_STATE, (state) => {
            return {
                ...state,
                loginBlockUntil: initialState.loginBlockUntil,
                loginPermanentBlock: initialState.loginPermanentBlock,
                submittedUserEmail: initialState.submittedUserEmail,
                loginConfirmFormError: initialState.loginConfirmFormError,
                loginFormErrorMessage: initialState.loginFormErrorMessage,
            };
        })
        .addCase(CONFIRM_LOGIN, (state) => {
            state.isLoadingLoginConfirmationForm = true;
        })
        .addCase(CONFIRM_LOGIN_SUCCESS, (state) => {
            state.isLoadingLoginConfirmationForm = false;
            state.loginConfirmFormError = undefined;
        })
        .addCase(CONFIRM_LOGIN_FAILURE, (state, action: Action) => {
            state.isLoadingLoginConfirmationForm = false;
            state.loginConfirmFormError = action.payload;
            handleAuthFailure(state, action.payload);
        })
        .addCase(CLEAR_ALL_PASSCODE_DIGITS, (state) => {
            state.loginConfirmFormError = undefined;
        })
        .addCase(REQUEST_PASSWORD_RESET, (state) => {
            state.isLoadingForgetPassword = true;
        })
        .addCase(REQUEST_PASSWORD_RESET_SUCCESS, (state) => {
            state.isLoadingForgetPassword = false;
            state.passwordResetLinkSent = true;
        })
        .addCase(REQUEST_PASSWORD_RESET_FAILURE, (state, action: Action) => {
            state.isLoadingForgetPassword = false;
            state.requestPasswordResetErrorMessage = action.payload;
        })
        .addCase(CLEAR_REQUEST_PASSWORD_RESET_STATE, (state) => {
            state.passwordResetLinkSent = initialState.passwordResetLinkSent;
            state.requestPasswordResetErrorMessage = initialState.requestPasswordResetErrorMessage;
        })
        .addCase(GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS, (state, action: Action) => {
            state.loginFormRememberedUserEmail = action.payload;
        })
        .addCase(CLEAR_CURRENT_USER, (state) => ({
            ...initialState,
            loginFormRememberedUserEmail: state.loginFormRememberedUserEmail,
        }))
        .addCase(SEND_CONFIRMATION_CODE, (state) => {
            state.isLoadingSendConfirmationCode = true;
        })
        .addCase(SEND_CONFIRMATION_CODE_SUCCESS, (state, action: Action) => {
            state.loginToken = action.payload.token;
            state.isLoadingSendConfirmationCode = false;
            state.sendConfirmationCodeBlockUntil = new Date(new Date().getTime() + RESEND_CONFIRMATION_CODE_COUNTDOWN_TIME);
        })
        .addCase(SEND_CONFIRMATION_CODE_FAILURE, (state) => {
            state.isLoadingSendConfirmationCode = false;
        })
        .addCase(CLEAR_BLOCK_SEND_CONFIRMATION_CODE, (state) => {
            state.sendConfirmationCodeBlockUntil = undefined;
        });

    addLoadingState([ACTIVATE_USER, ACTIVATE_USER_SUCCESS, ACTIVATE_USER_FAILURE], 'isLoadingActivateUser', builder);
    addLoadingState([SET_NEW_PASSWORD, SET_NEW_PASSWORD_SUCCESS, SET_NEW_PASSWORD_FAILURE], 'isLoadingSetNewPassword', builder);
    addLoadingState([SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_FAILURE], 'isLoadingSignUpForm', builder);
    addLoadingState([REFRESH_TOKEN, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE], 'isRefreshingSession', builder);
    addLoadingState([EXTEND_TOKEN_VALIDITY, EXTEND_TOKEN_VALIDITY_SUCCESS, EXTEND_TOKEN_VALIDITY_FAILURE], 'isRefreshingSession', builder);
};

export default createReducer(initialState, createAuthorisationReducerBuilderCases);
