import { createFutureDateMS } from '@manigo/manigo-commons';
import { createReducer } from '@reduxjs/toolkit';

import { decrypt, decryptClowd9Data } from 'utils/encryption-tools';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    COUNTDOWN_TIME,
    FETCH_CARD_SENSITIVE_DATA, FETCH_CARD_SENSITIVE_DATA_FAILURE, FETCH_CARD_SENSITIVE_DATA_SUCCESS,
    RESET_DATA,
    FETCH_CARD_SENSITIVE_DATA_CLOWD9_SUCCESS,
} from './actions.types';


export const cardSensitiveDataReducerName = 'cardSensitiveData';

const initialState = {
    cardFullNumber: undefined,
    cardCVV: undefined,
    cardPin: undefined,
    countdownTime: undefined,
    isLoadingFetchCardSensitiveData: false,

};
const createCardSensitiveDataBuilderCases = (builder) =>
    builder
        .addCase(FETCH_CARD_SENSITIVE_DATA, (state) => {
            state.isLoadingFetchCardSensitiveData = true;
            state.countdownTime = undefined;
        })
        .addCase(FETCH_CARD_SENSITIVE_DATA_SUCCESS, (state, action) => {
            state.countdownTime = createFutureDateMS(COUNTDOWN_TIME);
            state.isLoadingFetchCardSensitiveData = false;
            state.cardPin = decrypt(action.payload.pin);
            state.cardCVV = decrypt(action.payload.cvv);
            state.cardFullNumber = decrypt(action.payload.cardNumber);

        })
        .addCase(FETCH_CARD_SENSITIVE_DATA_FAILURE, (state) => {
            state.isLoadingFetchCardSensitiveData = false;
        })

        .addCase(RESET_DATA, () => {
            return initialState;
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return initialState;
        })
        .addCase(FETCH_CARD_SENSITIVE_DATA_CLOWD9_SUCCESS, (state, action) => {
            const { responsePayload, cipher } = action.payload;
            state.countdownTime = createFutureDateMS(COUNTDOWN_TIME);
            state.isLoadingFetchCardSensitiveData = false;
            state.cardPin = decryptClowd9Data(responsePayload.pin, cipher);
            state.cardCVV = decryptClowd9Data(responsePayload.cvv, cipher);
            state.cardFullNumber = decryptClowd9Data(responsePayload.cardNumber, cipher);
        });

export default createReducer(initialState, createCardSensitiveDataBuilderCases);
