import {
    AuthErrors, jwtDecode, generateSessionUuid, getJwt, isDateInTheFuture,
    getLocalStorageObjectItem,
    getSessionStorageObjectItem,
    setLocalStorageObjectItem,
    setSessionStorageObjectItem,
} from '@manigo/manigo-commons';
import { ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, delay, mergeMap, switchMap } from 'rxjs/operators';

import { MultiStepFormStepChangeDirections } from 'models/app/multiStepsFormModel';
import { ToastType } from 'models/app/toast';
import { DecodedJwtTokenData } from 'models/domain/token';
import { Epic } from 'models/meta/epic';

import {
    clientConfigKey, companyDetailsKey, permissionsKey, rememberMeLoginForm, sessionUuidKey, userPreferencesKey,
} from 'config/config';
import { forgotPasswordRootRoutePath, loginRootRoutePath, passcodeConfirmRootRoutePath } from 'config/routes';

import { setSessionUuid, showToast } from 'store/application/actions';
import {
    initialiseLoginSuccess,
    initialiseLoginFailure,
    confirmLoginSuccess,
    confirmLoginFailure,
    setNewPasswordSuccess,
    setNewPasswordFailure,
    requestPasswordResetSuccess,
    requestPasswordResetFailure,
    getRememberedUserEmailSuccess,
    getRememberedUserEmailFailure,
    signUpSuccess,
    signUpFailure,
    refreshTokenSuccess, refreshTokenFailure, activateUserSuccess, activateUserFailure, sendConfirmationCodeSuccess, sendConfirmationCodeFailure,
} from 'store/authorisation/actions';
import { clearCurrentUser, setCurrentUser } from 'store/current-user/actions';
import SET_CURRENT_USER_VARIANT from 'store/current-user/epics.helpers';
import { requestSetMultiStepsFormStep } from 'store/multi-steps-form/actions';
import { requestNavigation } from 'store/navigation/actions';

import { SignUpFormSteps } from 'components/pages/unauthorised/SignUpPage/SignUpPage.types';

import {
    ACTIVATE_USER, ACTIVATE_USER_SUCCESS,
    CONFIRM_LOGIN, CONFIRM_LOGIN_FAILURE,
    GET_REMEMBERED_LOGIN_USER_EMAIL,
    GET_TOKENS_FOR_DUPLICATED_TAB,
    INITIALISE_LOGIN, REFRESH_TOKEN,
    REQUEST_PASSWORD_RESET,
    REQUEST_PASSWORD_RESET_SUCCESS, SEND_CONFIRMATION_CODE,
    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_SUCCESS, SIGN_UP,
} from './actions.types';
import { createSignUpErrorMessage } from './epics.helpers';
import { clearAllPasscodeDigits } from '../otp/actions';


const generateNewSessionUuid = (http) => {
    const newSessionUuid = generateSessionUuid();
    setSessionStorageObjectItem(sessionUuidKey, newSessionUuid);
    http.setSessionUuid(newSessionUuid);

    return setSessionUuid(newSessionUuid);
};

export const onSignUp: Epic = (action$, state$, { authorisation, i18n }) => {
    return action$.pipe(
        ofType(SIGN_UP),
        switchMap(({ payload }) => {
            return from(authorisation.signUpCompany(payload)).pipe(
                switchMap((response) => {
                    return of(
                        signUpSuccess(response?.data),
                        requestSetMultiStepsFormStep(
                            SignUpFormSteps.REVIEW,
                            MultiStepFormStepChangeDirections.FORWARD,
                        ),
                    );
                }),
                catchError((error) => {
                    return of(
                        showToast({ type: ToastType.error, message: createSignUpErrorMessage(i18n.t, error) }),
                        signUpFailure({ error }),
                    );
                }),
            );
        }),
    );
};
export const onActivateUser: Epic = (action$, state$, { authorisation, i18n, http }) => {
    return action$.pipe(
        ofType(ACTIVATE_USER),
        switchMap(({ payload }) => {
            const decodedToken: DecodedJwtTokenData = jwtDecode(payload?.token);
            const tokenExpirationTime = decodedToken.exp * 1000; // XXX in milliseconds!

            if (isDateInTheFuture(tokenExpirationTime, 2)) {
                return from(authorisation.activateUser(payload)).pipe(
                    switchMap((response: any) => of(activateUserSuccess(response.data))),
                    catchError(() => of(activateUserFailure())),
                );
            }

            return of(
                generateNewSessionUuid(http),
                activateUserFailure(),
                showToast({
                    type: ToastType.error,
                    message: i18n.t('unauthorised:actionMessages.activateUserTokenHasExpired'),
                }),
                requestNavigation({ locationPathname: forgotPasswordRootRoutePath }),
            );
        }),
    );
};

export const onActivateUserSuccess: Epic = (action$, _, { i18n, http }) => {
    return action$.pipe(
        ofType(ACTIVATE_USER_SUCCESS),
        switchMap(() => {
            return of(
                generateNewSessionUuid(http),
                showToast({
                    type: ToastType.success,
                    message: i18n.t('unauthorised:actionMessages.activateUserSuccess'),
                }),
                requestNavigation({ locationPathname: loginRootRoutePath }),
            );
        }),
    );
};

export const onInitialiseLogin: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(INITIALISE_LOGIN),
        switchMap(({ payload: { email, password, rememberMe } }) => {
            if (rememberMe) {
                setLocalStorageObjectItem(rememberMeLoginForm, email);
            }
            return from(authorisation.loginInit({ email, password })).pipe(
                switchMap((response) => {
                    return of(
                        initialiseLoginSuccess(response?.data),
                        requestNavigation({ locationPathname: passcodeConfirmRootRoutePath }),
                    );
                }),
                catchError((error) => of(initialiseLoginFailure({
                    error,
                    email,
                }))),
            );
        }),
    );
};


export const onConfirmLogin: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(CONFIRM_LOGIN),
        switchMap(({ payload }) => {
            return from(authorisation.loginConfirm(payload)).pipe(
                switchMap((response) => {

                    return of(
                        confirmLoginSuccess(response.data),
                        clearAllPasscodeDigits(),
                        setCurrentUser(response.data, SET_CURRENT_USER_VARIANT.LOGIN),
                    );
                }),
                catchError((error) => of(confirmLoginFailure(error.data?.message))),
            );
        }),
    );
};

export const onConfirmLoginFailure: Epic = (action$) =>
    action$.pipe(
        ofType(CONFIRM_LOGIN_FAILURE),
        delay(1000),
        switchMap(({ payload }) => {
            const shouldNavigateToLogin = [
                AuthErrors.TEMPORARILY_BLOCKED_FOR,
                AuthErrors.TEMPORARILY_BLOCKED,
                AuthErrors.CONFIRMATION_TOKEN_EXPIRED,
                AuthErrors.USER_LOCKED,
            ].includes(payload);

            return shouldNavigateToLogin
                ? of(requestNavigation({ locationPathname: loginRootRoutePath }))
                : EMPTY;
        }),
    );


export const onSetNewPassword: Epic = (action$, state$, { authorisation, i18n, http }) => {
    return action$.pipe(
        ofType(SET_NEW_PASSWORD),
        switchMap(({ payload }) => {
            const decodedToken: DecodedJwtTokenData = jwtDecode(payload.token);
            const tokenExpirationTime = decodedToken.exp * 1000; // XXX in milliseconds!

            if (isDateInTheFuture(tokenExpirationTime, 2)) {
                return from(authorisation.setNewPassword(payload)).pipe(
                    switchMap((response) => of(setNewPasswordSuccess(response.data))),
                    catchError(() => of(setNewPasswordFailure())),
                );
            }

            return of(
                generateNewSessionUuid(http),
                setNewPasswordFailure(),
                showToast({
                    type: ToastType.error,
                    message: i18n.t('unauthorised:actionMessages.setNewPasswordTokenHasExpired'),
                }),
                requestNavigation({ locationPathname: forgotPasswordRootRoutePath }),
            );
        }),
    );
};

export const onSetNewPasswordSuccess: Epic = (action$, _, { i18n, http }) => {
    return action$.pipe(
        ofType(SET_NEW_PASSWORD_SUCCESS),
        switchMap(() => {
            return of(
                generateNewSessionUuid(http),
                showToast({
                    type: ToastType.success,
                    message: i18n.t('unauthorised:actionMessages.setNewPasswordSuccess'),
                }),
                requestNavigation({ locationPathname: loginRootRoutePath }),
            );
        }),
    );
};

export const onRequestPasswordReset: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(REQUEST_PASSWORD_RESET),
        switchMap(({ payload }) => {
            return from(authorisation.requestPasswordReset(payload)).pipe(
                switchMap((response) => of(requestPasswordResetSuccess(response.data))),
                catchError((response) => of(requestPasswordResetFailure(response.data?.message))),
            );
        }),
    );
};

export const onRequestPasswordResetSuccess: Epic = (action$, _, { http }) => {
    return action$.pipe(
        ofType(REQUEST_PASSWORD_RESET_SUCCESS),
        switchMap(() => of(generateNewSessionUuid(http))),
    );
};
export const onGetRememberedUserEmail: Epic = (action$) => action$.pipe(
    ofType(GET_REMEMBERED_LOGIN_USER_EMAIL),
    switchMap(() => {
        const storedUserNameOrEmail = getLocalStorageObjectItem(rememberMeLoginForm);
        if (storedUserNameOrEmail) {
            return of(getRememberedUserEmailSuccess(storedUserNameOrEmail));
        }

        return of(getRememberedUserEmailFailure());
    }),
);


export const onGetTokensForDuplicatedTab: Epic = (action$, _, { authorisation }) => {
    return action$.pipe(
        ofType(GET_TOKENS_FOR_DUPLICATED_TAB),
        switchMap(({ payload }) => {
            return from(authorisation.getTokensForDuplicatedSession(payload.requestPayload))
                .pipe(
                    switchMap((response) => of(setCurrentUser({ ...response.data, ...payload.sessionStorageData }, SET_CURRENT_USER_VARIANT.DUPLICATE_TAB))),
                    catchError(() => of(clearCurrentUser())),
                );
        }),
    );
};

export const onRefreshToken = (action$, state$, { authorisation }) => action$.pipe(
    ofType(REFRESH_TOKEN),
    mergeMap(() => {
        const jwtTokens = getJwt();
        const permissions = getSessionStorageObjectItem(permissionsKey);
        const configuration = getSessionStorageObjectItem(clientConfigKey);
        const userPreferences = getSessionStorageObjectItem(userPreferencesKey);
        const company = getSessionStorageObjectItem(companyDetailsKey);

        return from(authorisation.extendTokenValidity(jwtTokens.refreshToken)).pipe(
            switchMap((response: any) => of(
                refreshTokenSuccess(),
                setCurrentUser({
                    ...response.data,
                    permissions,
                    configuration,
                    userPreferences,
                    company,
                }, SET_CURRENT_USER_VARIANT.REFRESH_SESSION),
            )),
            catchError(() => of(refreshTokenFailure(), clearCurrentUser())),
        );
    }),
);


export const onSendConfirmationCode: Epic = (action$, state$, { authorisation, i18n }) => {
    return action$.pipe(
        ofType(SEND_CONFIRMATION_CODE),
        switchMap(({ payload }) => {
            return from(authorisation.sendConfirmationCode(payload)).pipe(
                switchMap((response) => {
                    return of(
                        sendConfirmationCodeSuccess(response.data),
                        showToast({
                            type: ToastType.success,
                            message: i18n.t('unauthorised:actionMessages.sendConfirmationCodeSuccess'),
                        }),
                    );
                }),
                catchError(() => of(
                    sendConfirmationCodeFailure(),
                    showToast({
                        type: ToastType.error,
                        message: i18n.t('unauthorised:actionMessages.sendConfirmationCodeFailure'),
                    }),
                )),
            );
        }),
    );
};

export default [
    onActivateUser,
    onActivateUserSuccess,
    onSignUp,
    onInitialiseLogin,
    onConfirmLogin,
    onConfirmLoginFailure,
    onSetNewPassword,
    onSetNewPasswordSuccess,
    onGetRememberedUserEmail,
    onRequestPasswordReset,
    onRequestPasswordResetSuccess,
    onGetTokensForDuplicatedTab,
    onSendConfirmationCode,
];
