import { jwtDecode } from '@manigo/manigo-commons';


const parseCardNumber = (cardNumber: string): string => cardNumber.match(/(\d{4})/g)?.join(' ') || '';

export { parseCardNumber };

export const CARD_VENDORS = { CLOWD9: 'CLOWD9' };

export const decodeJwtAndExtractVendor = (jwtToken?: string): string | null => {
    if (!jwtToken) return null;

    try {
        const decodedToken: { clientSettings?: { cardsVendor?: string } } = jwtDecode(jwtToken);
        return decodedToken?.clientSettings?.cardsVendor || null;
    } catch (error) {
        console.warn('Failed to decode JWT token:', error);
        return null;
    }
};

