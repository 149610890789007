import { AesEncryptedText } from '@manigo/manigo-domain-typings';
import CryptoJS from 'crypto-js';
import forge from 'node-forge';

import { PERSONAL_APP_AES_KEY } from 'config/environment';


export const decrypt = (encryptedText: AesEncryptedText): string => {
    // convert to bytes
    const keyByteArray = CryptoJS.enc.Utf8.parse(PERSONAL_APP_AES_KEY);
    const decryptedByteArray = CryptoJS.AES.decrypt(encryptedText, keyByteArray, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding });
    // XXX nasty '\x00' case
    // decoded strings contain control characters that are incorrectly rendered by Chrome on Windows (as rectangles)
    // eslint-disable-next-line no-control-regex
    return decryptedByteArray.toString(CryptoJS.enc.Utf8).replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
};


export const decryptClowd9Data = (encryptedText: string, cipher: string) => {
    if (!encryptedText) {
        return ''; // Return empty if no data like we have for pin
    }
    try {
        const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedText);
        const encryptedHex = CryptoJS.enc.Hex.stringify(encryptedBytes);
        const ivHex = encryptedHex.substring(0, 32); // First 16 bytes (32 hex chars)
        const ciphertextHex = encryptedHex.substring(32); // Remaining bytes (Ciphertext)
        const iv = CryptoJS.enc.Hex.parse(ivHex); // Parse IV as WordArray
        const ciphertext = CryptoJS.enc.Hex.parse(ciphertextHex); // Parse ciphertext as WordArray
        const key = CryptoJS.enc.Utf8.parse(cipher); // Parse cipher into key

        const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext });

        const bytes = CryptoJS.AES.decrypt(
            cipherParams,
            key,
            {
                iv,
                mode: CryptoJS.mode.CFB,
                padding: CryptoJS.pad.NoPadding,
            },
        );

        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        return decrypted || '';

    } catch (error) {
        console.error('Decryption failed for', error);
        return '';
    }
};


export const generateRandomCipher = (): string => {
    const array = new Uint8Array(12); // 12 bytes = 24 hex chars
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};


export const encryptWithPublicKey = (publicKey: string | forge.pki.rsa.PublicKey, cipher: string): string => {
    let publicKeyForge: forge.pki.rsa.PublicKey;

    if (typeof publicKey === 'string') {
        publicKeyForge = forge.pki.publicKeyFromPem(publicKey) as forge.pki.rsa.PublicKey;
    } else {
        publicKeyForge = publicKey;
    }

    const encrypted = publicKeyForge.encrypt(cipher, 'RSAES-PKCS1-V1_5'); // Use RSA PKCS1 v1.5 padding
    return forge.util.encode64(encrypted);
};


export const formatPublicKey = (rawKey: string): string => {
    const formattedKey = rawKey.match(/.{1,64}/g)?.join('\n') ?? rawKey;
    return `-----BEGIN PUBLIC KEY-----\n${formattedKey}\n-----END PUBLIC KEY-----`;
};


export const prepareEncryptedCipher = (publickKey: string) => {
    const publicKey = formatPublicKey(publickKey);
    const publicKeyForge = forge.pki.publicKeyFromPem(publicKey);
    const cipher = generateRandomCipher();
    const encryptedCipher = encryptWithPublicKey(publicKeyForge, cipher);
    return { encryptedCipher, cipher };
};
