import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap } from 'rxjs/operators';

import { ModalName } from 'models/app/modal';
import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { CARD_VENDORS, decodeJwtAndExtractVendor } from 'utils/card-tools';
import { prepareEncryptedCipher } from 'utils/encryption-tools';

import { showToast } from 'store/application/actions';
import { hideModal, showModal } from 'store/modal/actions';
import { clearScaChallenge } from 'store/sca-challange/actions';

import { fetchCardSensitiveDataClowd9Success, fetchCardSensitiveDataFailure, fetchCardSensitiveDataSuccess, resetData } from './actions';
import { COUNTDOWN_TIME, FETCH_CARD_SENSITIVE_DATA, FETCH_CARD_SENSITIVE_DATA_SUCCESS, FETCH_CARD_SENSITIVE_DATA_CLOWD9_SUCCESS } from './actions.types';
import { createFetchCardSensitiveDataErrorMessage } from './epics.helpers';


export const onFetchCardSensitiveData: Epic = (action$, state$, { i18n, cards }) => action$.pipe(
    ofType(FETCH_CARD_SENSITIVE_DATA),
    switchMap(({ payload }) => {
        const state = state$.value;
        const jwtToken = state.currentUser?.jwtToken;
        const cardsVendor = jwtToken ? decodeJwtAndExtractVendor(String(jwtToken)) : null;

        if (cardsVendor === CARD_VENDORS.CLOWD9) {
            return from(cards.fetchCardPublicKey(payload)).pipe(
                switchMap((response) => {
                    const { encryptedCipher, cipher } = prepareEncryptedCipher(response.data.key);
                    return from(
                        cards.getCardSensitiveData(payload, encryptedCipher),
                    ).pipe(
                        switchMap((cardDataResponse) => {
                            return of(
                                fetchCardSensitiveDataClowd9Success(cardDataResponse.data, cipher),
                                clearScaChallenge(),
                                showModal({
                                    modalType: ModalName.CARD_SENSITIVE_DATA,
                                    modalProps: { title: i18n.t('cards:modals.cardSensitiveData.title'), modalSize: 'lg' },
                                }),
                            );
                        }),
                        catchError((error) =>
                            of(
                                fetchCardSensitiveDataFailure(),
                                showToast({ type: ToastType.error, message: `Sensitive Data API Error: ${error.message}` }),
                                clearScaChallenge(),
                            ),
                        ),
                    );
                }),
                catchError((error) =>
                    of(
                        fetchCardSensitiveDataFailure(),
                        showToast({ type: ToastType.error, message: `Public Key API Error: ${error.message}` }),
                    ),
                ),
            );
        }

        // Existing Flow for Non-CLOWD9 Vendors
        return from(cards.getCardSensitiveData(payload)).pipe(
            switchMap((response) => {
                return of(
                    fetchCardSensitiveDataSuccess(response.data),
                    clearScaChallenge(),
                    showModal({
                        modalType: ModalName.CARD_SENSITIVE_DATA,
                        modalProps: { title: i18n.t('cards:modals.cardSensitiveData.title'), modalSize: 'lg' },
                    }),
                );
            }),
            catchError((error) => of(
                fetchCardSensitiveDataFailure(),
                showToast({ type: ToastType.error, message: createFetchCardSensitiveDataErrorMessage(i18n.t, error) }),
                clearScaChallenge(),
            ),
            ),
        );
    }),
);

export const onFetchCardSensitiveDataSuccess: Epic = (action$) =>
    action$.pipe(
        ofType(FETCH_CARD_SENSITIVE_DATA_SUCCESS, FETCH_CARD_SENSITIVE_DATA_CLOWD9_SUCCESS),
        debounceTime(COUNTDOWN_TIME),
        mergeMap(() => of(resetData(), hideModal())),
    );
export default [
    onFetchCardSensitiveData,
    onFetchCardSensitiveDataSuccess,
];
