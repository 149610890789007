import { ApiError, generateUuidV4, HttpStatus } from '@manigo/manigo-commons';
import { SubmitScaChallengeActionPayload } from '@manigo/manigo-domain-typings';
import queryString from 'query-string';

import { ToastType } from 'models/app/toast';

import { developmentMode } from 'config/environment';

import { showToast } from 'store/application/actions';
import { clearCurrentUser } from 'store/current-user/actions';

import { HttpRequestConfig } from './httpService.types';


export const withTotalCount = { withTotal: true };
export const withSkipImages = { skipImages: true };

export const createErrorActions = (error, i18n) => {
    const handlers = [
        {
            predicate: () => error.unauthorized && !error.handled,
            handler: () => [clearCurrentUser()],
        },
        {
            predicate: () => true,
            handler: () => [showToast(
                {
                    type: ToastType.error,
                    message: developmentMode ? error?.message : i18n.t('unauthorised:genericApiError'),
                    id: 'network',
                },
            )],
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createApiError = (fetchError, i18n): ApiError => {
    const { config } = fetchError;
    const status = fetchError.response?.status;

    const error = new Error(fetchError?.apiResponseObject?.message || '') as ApiError;
    error.messageCode = fetchError.apiResponseObject?.message_code;
    error.handled = !config.noErrorHandling;
    error.forbidden = status === HttpStatus.FORBIDDEN;
    error.unauthorized = status === HttpStatus.UNAUTHORIZED || (status === HttpStatus.INTERNAL_SERVER_ERROR && fetchError.message === 'jwt expired');
    error.offline = status === HttpStatus.SERVICE_UNAVAILABLE || status === HttpStatus.GATEWAY_TIMEOUT;

    error.status = status;
    error.message = fetchError.response?.message || fetchError.apiResponseObject.message;
    error.data = fetchError.apiResponseObject;
    error.actions = createErrorActions(error, i18n);

    return error;
};


export const sanitizeQueryParams = (config: HttpRequestConfig) => {
    return config?.params ? queryString.stringify(config?.params, { arrayFormat: 'bracket' }) : undefined;
};

export const addRequestHeaders = (headers: Headers, config: HttpRequestConfig) => {
    headers.set('request-uuid', generateUuidV4());

    if (config?.customHeaders) {
        config.customHeaders.forEach((customHeader) => {
            if (customHeader?.key && customHeader?.value) {
                headers.set(customHeader?.key, customHeader?.value);
            }
        });
    }
};

export const cleanupCustomRequestHeaders = (headers: Headers, config: HttpRequestConfig) => {
    headers.set('request-uuid', generateUuidV4());
    if (config?.customHeaders) {
        config.customHeaders.forEach((customHeader) => {
            if (customHeader?.key && headers.has(customHeader.key)) {
                headers.delete(customHeader.key);
            }
        });
    }
};


export const addScaHeaders = (rawRequestPayload: SubmitScaChallengeActionPayload, config: HttpRequestConfig = {}): HttpRequestConfig => {
    return {
        ...config,
        customHeaders: [
            { key: 'X-Challenge-Signature', value: rawRequestPayload.challengeSignatureHeaderValue },
            { key: 'X-Challenge-Id', value: `${rawRequestPayload.challengeIdHeaderValue}` },
        ],
    };

};

export const addCipherHeaders = (encryptedCipher?: string, config: HttpRequestConfig = {}): HttpRequestConfig => {
    return {
        ...config,
        customHeaders: [
            ...(config.customHeaders || []), // Preserve existing headers
            ...(encryptedCipher
                ? [{ key: 'encrypted-cipher', value: encryptedCipher }] // Add only if encryptedCipher exists
                : []),
        ],
    };
};


export function headersToPlainObject(headers: Headers): { [key: string]: string } {
    const plainObject: { [key: string]: string } = {};

    headers.forEach((value, key) => {
        plainObject[key] = value;
    });

    return plainObject;
}
